import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLQuery } from '@aws-amplify/api';
import { listPosts } from '../../graphql/queries';
import { ListPostsQuery } from '../../API';

import Article from "../../components/article/article";





export default  function Blog(){
    const [posts, setPosts] = useState<any[]>([])

    useEffect(() => {
        API.graphql<GraphQLQuery<ListPostsQuery>>(graphqlOperation(listPosts)).then(data => {
            const myPosts = data?.data?.listPosts?.items ? [...data?.data?.listPosts?.items] : [];
            setPosts(myPosts);
            
        });
    }, [])
    
    return(
        <div className="container">
            <div className="row">
                <article className="col-md-12">
                    <h1>Handy Husband Blog</h1>
                    {posts.map((article: { id: string; title: string; content: string; createdAt: string; username: string; }) => {
                        console.log(article)
                        return (<Article key={article.id}title={article.title} description={article.content} date={article.createdAt} username={article.username} />)
                    })}
                </article>
            </div>
        </div>
    )
}


