import React from 'react';
import { Link } from 'react-router-dom'
import logo from '../../images/handy-husband-logo.png'
import './menu.css'

function Menu() {
  return (
    <div className="menu container mb-3">
        <div className="row">
            <div className="col-md-3 logo p-2">
              <img src={logo} width="175" className="px-3" alt="Handy Husband Logo" />
            </div>
            <div className="col-md-9">
              <ul>
                  <li className="p-3"><Link to="/">Home</Link></li>
                  <li className="p-3"><Link to="/products">Products</Link></li>
                  <li className="p-3"><Link to="/blog">Blog</Link></li>
                  <li className="p-3"><Link to="/about">About</Link></li>
              </ul>
            </div>
        </div>
    </div>
  );
}

export default Menu;
