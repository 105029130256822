import React, { lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import Menu from './components/menu/menu';
//import { SingleColLayout } from './layout/SingleColLayout'
import { RightColLayout } from './layout/RightColLayout'
import Home from './pages/home/Home'
import About from './pages/about/About'
import Blog from './pages/blog/Blog'
import Products from './pages/products/Products'
import ProductList from './pages/productList/ProductList'
import NoMatch from './pages/nomatch/NoMatch'
import SocialBar from './components/socialBar/socialBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


Amplify.configure(awsconfig);

const Footer = lazy(() => import('./components/footer/footer'));

function App() {
  return (
    <div className="App">
      <SocialBar />
      <Menu />
      <Routes>
        <Route path="/" element={<RightColLayout />}>
          <Route index element={<Home />} />

          <Route path="/products/:category" element={<ProductList />} />
          <Route path="/products" element={<Products />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about" element={<About />} />
          {/*<Route path="about" element={<About />} />
          <Route path="dashboard" element={<Dashboard />} />

          {/*Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
  routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
