import React from 'react';
import { Outlet } from "react-router-dom"


const RightColLayout = () => 
  <div className="container">
    <div className="row">
        <div className="col-md-9">
            
            <Outlet />
        </div>
        <div className="col-md-3">
            <div className="colBlock mb-3">
                <span className="title mb-2">Share</span>
                <div className="sharethis-inline-share-buttons"></div>
            </div>

            <div className="colBlock mb-3">
                <span className="title mb-2">Latest blog</span>
            </div>
            
            <div className="colBlock mb-3">
                <span className="title mb-2">Latest products</span>
            </div>
        </div>
    </div>
  </div>;


export { RightColLayout }