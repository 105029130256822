/* eslint-disable react/style-prop-object */
import React, {useEffect, useState} from "react";
import './productList.css'
import { Link, useParams } from "react-router-dom";



export default function ProductList(){

    // Load the category slug from the url
    let { category } = useParams<"category">();

    const [product, setProduct] = useState({
        title: category,
        description: "",
        link: ""
    })
    

    console.log('==c',category)
    return(
        <div className="container">
            <div className="row">
                <article className="col-md-12">
                    <h1>{product.title}</h1>
                    {product.description}
                    <div className="amazonProduct">
                        <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" 
                            title="Coffee pod organizer" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=thehandyhusba-20&language=en_US&marketplace=amazon&region=US&placement=B09TT6QR15&asins=B09TT6QR15&linkId=d682c1d172edeb67ccaabe846b6a753a&show_border=false&link_opens_in_new_window=true"></iframe>
                    </div>
                </article>
            </div>
        </div>
    )
}


