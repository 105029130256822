import React from "react";


export default function About(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h1>About the tech-savvy handy husband</h1>
                <p>I am a tech-savvy and handy individual with a deep-rooted passion for all things technology. With a strong background in the field, I have always been captivated by the ever-evolving world of gadgets, computers, and digital innovation. My love for technology has only grown stronger since becoming a father to my two incredible boys, who are currently 1 and 2 years old.</p>
                <p>With my expertise in technology and my practical skills, I am always ready to lend a helping hand to friends, family, and even my community. Whether it's troubleshooting computer issues, setting up smart home devices, or providing guidance on the latest tech trends, I am the go-to person among my social circle. I take pride in my ability to simplify complex technological concepts and make them accessible to everyone.</p>
                <p>As a father, I find immense joy in nurturing my children's curiosity and introducing them to the wonders of technology. Whether it's showing them how to interact with touchscreens or exploring educational apps together, I am committed to fostering their interest in the digital world from an early age. Being able to combine my passion for technology with my role as a father is truly rewarding, as I witness the excitement and wonder on my boys' faces as they discover new possibilities.</p>
                </div>
            </div>
        </div>
    )
}