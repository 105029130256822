import React, { FunctionComponent } from "react";
import parse from 'html-react-parser';

export interface ArticleProps {
    key: string;
    title: string;
    description: string;
    date: string;
    username: string;
}
const Article: FunctionComponent<ArticleProps> = ({key, title, description, date, username}) => {

    console.log(typeof title)
    return(
        <article key={key} className="col-md-12">


            <h2>{title}</h2>
            {parse(description)}
            <span className="articleDate">{date} by: {username}</span>

        </article>
    )
}

export default Article

