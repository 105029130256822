import React from "react";


export default function NoMatch(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1>404 - Page not found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                </div>
            </div>
        </div>
    )
}