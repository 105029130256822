import React from "react";
import {ReactComponent as FacebookIcon} from '../../images/icon/facebook.svg'
import {ReactComponent as InstagramIcon} from '../../images/icon/instagram-square.svg'
import {ReactComponent as PinterestIcon} from '../../images/icon/pinterest.svg'
import {ReactComponent as YoutubeIcon} from '../../images/icon/youtube.svg'
import './socialBar.css'

const SocialBar = () => {
    return (
        <div className="socialBar p-2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                <a href="https://www.facebook.com/profile.php?id=100094447912185" 
                    target="_BLANK" 
                    rel="nofollow noreferrer" 
                    title="Handy Husband Facebook page"
                    className="mx-2">
                    <FacebookIcon fill="#fff" width="25" />
                </a>
                <a href="xx" 
                    target="_BLANK" 
                    rel="nofollow" 
                    title="Handy Husband Instagram account"
                    className="mx-2">
                    <InstagramIcon fill="#fff" width="25" />
                </a>
                <a href="https://www.pinterest.com/HandyHusband1" target="_BLANK" rel="nofollow noreferrer" title="Handy Husband Pinterest"
                    className="mx-2">
                    <PinterestIcon fill="#fff" width="25" /></a>
                <a href="https://www.youtube.com/@HandyHusband" target="_BLANK" rel="nofollow noreferrer" title="Handy Husband Youtube channel"
                    className="mx-2">
                    <YoutubeIcon fill="#fff" width="25" /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SocialBar;